<!--
data: 
- title: text
- subTitle: text
- text: text
- image: image
- flipOrientation: bool
- buttons: list (title: text, text: text, buttonText: text, url: text)
- style: select (light: text, dark: text, color: text),
- columns: select ('4-8': '33% / 67%'', '5-7': '41% / 59%', '6-6': '50% / 50%', '7-5': '59% / 41%', 8-4': '67% / 33%')
-->

<template>
	<div class="cs-visual-narrative-segment-block cs-block" :class="styleClass">
		<div class="cs-block-base" :class="rootClasses">
			<div class="container">
				<div class="row">
					<div class="cs-col">
						<div style="height: 100%">
							<div class="text">
								<h2 class="cs-title">{{ data.title }}</h2>
								<h3 class="cs-sub-title" v-if="data.subTitle">
									{{ data.subTitle }}
								</h3>
								<span class="cs-text" v-html="data.text"></span>
								<div v-if="data.buttons">
									<Buttons :data="data.buttons" :cs_style="data.style" />
								</div>
							</div>
						</div>
					</div>
					<div class="cs-col">
						<div class="image">
							<CSMedia :data="data.image" :support="['image', 'video']" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "VisualNarrativeSegment",
	components: {
		Buttons: () => import("../components/CSUtilsButtons.vue"),
		CSMedia: () => import("../components/CSMedia.vue"),
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			blocks: [],
		}
	},
	computed: computed('VisualNarrativeSegment')
}
</script>
<style lang="scss" scoped>
@import "../styles/main.scss";

.cs-block.cs-visual-narrative-segment-block {
	.text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
	}
	.image {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		::v-deep .cs-media {
			width: 100%;
			img {
				width: 100%;
				height: auto;
				object-fit: cover;
			}
			video {
				width: 100%;
			}
		}
	}
    // .cs-block-base.cs-distribution-4-8,
    // .cs-block-base.cs-distribution-5-7 {
    //     ::v-deep .cs-advanced-button .button-group {
    //             justify-content: space-between;
    //             .additional-info {
    //                 width: 50%;
    //                 @media (max-width: 500px) {
    //                     width: 100%;
    //                 }
    //             }
    //             .button {
    //                 width: 40%;
    //                 @media (max-width: 500px) {
    //                     width: 70%;
    //                 }
    //             }
    //         }
    // }
}
</style>
